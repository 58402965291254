.g-btn-primary{
    background-color: #254a9a !important;
    color: rgb(255, 255, 255) !important;
}

.g-btn-warning{
  background-color: #e64040 !important;
  color: rgb(255, 255, 255) !important;
}

.g-btn-danger{
  background-color: #f50057 !important;
  color: rgb(255, 255, 255) !important;
}

.g-btn-pink{
  background-color: #d08f8e !important;
  color: rgb(255, 255, 255) !important;
}

.g-btn-secondary{
    background: #C1D0F0 !important;
    color: #254a9a !important;
}

.g-btn-elevated{
    transition: all ease 0.2s;
}

.g-btn-elevated:hover {
    transform: translateX(-2px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    border-radius:4px; 
  }

  .languageSwitch{
    border:1px #ffffff solid;
    background-color:#244a9a !important;
    border-radius:4px;
    width:55px;
  }

  .fullScreen{
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    background-color:#d08f8e !important;
    border-radius:4px; 
    border: 2px #ffffff solid;
  }

  .breadcrumb-project:hover{
    background-color: #254a9a !important;
    border-radius: 4px !important;
    color:"#fff" !important;
  }

 