#g-wrapper{
  position: relative;
  display: flex;
  flex-direction:row;
  flex: 1 0;
  width:100%;
  margin-top: 1px;
  min-height: calc(100% - #{$header-height});
}

.ty-body1 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.custom-scrollBar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 0px;
}

.custom-scrollBar.g-scroll-5::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f5f5f5;
}

.custom-scrollBar::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #f5f5f5;
}

.custom-scrollBar::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background:linear-gradient(148deg, #166aa8 0%, rgba(185,166,178,1) 80%, rgba(199,136,147,1) 100%) !important;
  //linear-gradient(187deg, rgb(36, 74, 154,1) 40%, rgb(208, 143, 142,1) 67%) !important;
  //background:linear-gradient(157deg, rgba(36,74,154,1) 40%, #b2c3f8 67%);
  
}

.grid-fields {
  width: 90% !important;
  margin-left: 20px !important;
  margin-bottom: 5px !important;
}

.fixed-background {
  background: linear-gradient(148deg, #166aa8 0%, rgba(185,166,178,1) 80%, rgba(199,136,147,1) 100%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.login-card {
  display: flex;
  flex-direction: row;
  border-radius: 0;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  @include depth(1);

  .login-image {
    width: 50%;
    background: url($login-bg) no-repeat center top;
    background-size: cover;
    padding: 50px 20px;

    .h3 {
      line-height: 0.8rem;
    }
  }

  .login-side {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  @include respond-below(md) {
    flex-direction: column;

    .login-image {
      width: 100%;
      padding: 60px;
    }

    .login-side {
      width: 100%;
      padding: 5px;
    }
  }

  @include respond-below(sm) {
    p.h2 {
      font-size: 1.6rem;
    }
  }

  @include respond-below(xs) {
    flex-direction: column;

    .image-side {
      padding: 35px 30px;
    }

    .form-side {
      padding: 35px 30px;
    }

    .logo-single {
      margin-bottom: 20px;
    }

    p.h2 {
      font-size: 1.4rem;
    }
  }
}

.content {
  height: calc(100vh - 85px);
  //width:100%;
  margin: 2px;
  //min-width:calc(100% - #{$main-menu-width} - #{$sub-menu-width} - 10px);
  z-index: 4;
  //margin-left: calc(#{$main-menu-width} + #{$sub-menu-width} + 10px);
  transition: width 2s;
  overflow: hidden;
  flex-grow: 1;
}
.tddContainer{
  padding: 10px;
}
.subAssetListcontainer{
  padding: 20px;
}
.customQuill .ql-container {
  height: 75% !important;
}
.grid-fields-no-m {
  width: 90% !important;
}
.customQuill .ql-container .ql-editor {
  min-height:150px;
}
.g-content {
  height: calc(100vh - 85px) !important;
  padding: 5px;
  width:100%
}

.appBar{
  height: 40px !important;
background-color: #d08f8e !important;
position: sticky !important;
z-Index: 5 !important;
color:#fff !important;
}



.dialog-appBar{
  position: sticky !important;
  z-Index: 0 !important;
  background: #244999;
  background: $ta-background !important;
  color: #ffffff;
  height: 40px;
}

.dialog-toolbar{
  min-height: 40px !important;
}

.custom-grid-container {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.grid-fields {
  width: 90% !important;
  margin-left: 20px !important;
}

.custom-section-heading {
  border-bottom: 2px solid #254a9a;
  color: #254a9a;
  font-weight: 400;
  margin-bottom: 14px;
  line-height: 39px;
  width: 95%;
  margin-left: 20px !important;
}

.toolbar-heading{
  color: #254a9a;
  font-weight: 400;
}

.gridcolumn-header{
  position: sticky !important;
  z-Index: 0 !important;
  background-color: #254a9a;
  color:#ffffff !important;
}

.data-grid{
  .MuiDataGrid-toolbarContainer {
    width:100%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .MuiFormControl-root{
      width:60%;
    }
    }
}

.data-grid{
  border-radius: 5px;
  .MuiDataGrid-columnHeaders{
    background-color: #254a9a;

    .MuiDataGrid-columnHeaderTitleContainer{
      justify-content: start;
    }

    .MuiDataGrid-columnHeaderTitle{
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiDataGrid-columnSeparator{
      color:$theme-color-6 !important
    }
    .MuiDataGrid-menuIconButton {
      color:#ffffff !important
    }
    .MuiDataGrid-sortIcon{
      color:#ffffff !important
    }

    .MuiDataGrid-row >.MuiDataGrid-cell{
      white-space: pre-wrap !important;
      max-height: fit-content !important;
    }
  }

}

.g-button{
background-color: #254a9a !important;
}
.g-comparison-divider { 
  width:4px;
  margin:5px !important;
  background: linear-gradient(157deg, rgb(36, 74, 154) 40%, #d08f8e 67%) !important;}


.g-divider-vertical{
  height: 5px;
  background: linear-gradient(157deg, rgb(36, 74, 154) 60%, #d08f8e 67%) !important;
}

.footer{
  height: 40px;
  position: relative;
  background-color: #ffffff !important;
}

.g-bullet-list li::marker {
	color: #254a9a;
}
.data-grid-container{
  border-radius: 4px;
  margin-left:2px
}
.rdg.fill-grid{
  block-size: 100%;
}

.g-drag-list-item{
  border-radius:5px;
  padding:8px !important;
}
.g-border-gradient-drag{
  border-width: 4px !important;
  border-style: dotted !important;
  border-image: linear-gradient(to right, #244999, #d08f8e) 1 !important;
}

.g-white-background{
  background-color: #ffffff !important;
}

.text-html {
  ul li{
    list-style-type:square;
  }
  ul li::marker{
    color: #254a9a;
  }
}

.mention-User-Tag{
  color: #254a9a;
  font-weight: 600;
  font-style: italic;
}

.text-removed {
  background:#fdb8c0 !important;
}

.text-added {
  background:#acf2bd !important;
}


.text-removed > * {
  background:#fdb8c0 !important;
}

.text-added > * {
  background:#acf2bd !important;
}


@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typewriter::after {
  content: '|'; /* Simulate the blinking cursor */
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  animation: typing 2s steps(30, end) infinite; /* Adjust the duration as needed */
}

// @media screen and (min-width: 1400px) {
//   .content {
//     max-width: 1370px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }
// @media screen and (min-width: 1600px) {
//   .content {
//     max-width: 1570px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }
// @media screen and (min-width: 1900px) {
//   .content {
//     max-width: 1870px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

.sticky-text{
  position: sticky;
  left: 0;
  background-color: white;
  top: 40px;
}
.g-drag:hover{
  border-radius: 4px;
}
.g-copy:hover{
  background-color: #6fc665;
  border-radius: 4px;
  color:"#fff" !important;
}
.g-delete:hover{
  background-color: #f50057;
  border-radius: 4px;
  color:"#fff" !important;
}
.g-edit:hover{
  background-color: #254a9a !important;
  border-radius: 4px !important;
  color:"#fff" !important;
}
.g-structure:hover{
  background-color: #d08f8e;
  border-radius: 4px;
  color:"#fff" !important;
}

.action-item:hover .action {
  opacity: 1;
  visibility: visible;
}

.action {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  border:none
}

.custom-table th, .custom-table td {
  padding: 8px;
  text-align: left;
  border: none; /* Default border style */
}

.custom-table th {
  background-color: #f2f2f2; /* Header background color */
}

.custom-table tr:last-child td {
  border-bottom: 2px solid black; /* Solid bottom border for last row */
}

.custom-table td:first-child {
  border-right: 2px dotted #dddddd; /* Dotted right border for first column */
  border-bottom: 2px dotted #dddddd !important; 
}
.custom-table td:nth-child(2) {
  border-bottom: 2px dotted #dddddd !important; 
}

.custom-table td:last-child {
  border-right: none; /* Remove right border for last column */
}

.label-text{
  font-size: 11px !important;
}

.g-selected-parent-list{
  background-color: #eff2f5;
  border-radius:4px;
  box-shadow: 2px 2px 0.5rem rgba(0,0,0,0.5);
  width: 98% !important;
  margin: 0px 2px;
  border-left: 6px solid #C1D0F0;
}

.g-selected-list-item {
  background: #d08f8e;
  color: #ffffff;
  box-shadow: 1px 1px 0.5rem rgba(0,0,0,0.5);
  width: 98% !important;
  margin:2px 2px;
  border-radius: 4px;
}

.g-divider {
  width:100%;
  height:4px !important;
  background: linear-gradient(157deg, #244a9a 40%, #d08f8e 67%) !important;
}

.g-contact {
  background-color: #dce6fc80 !important
}


.text-data  .marker-yellow {
  background-color: #fdfd77 !important;
}

.text-data  .marker-green {
  background-color: #62f962 !important;
}

.text-data  .marker-pink {
  background-color: #fc7899 !important;
}


.text-data  .marker-blue {
  background-color:#72ccfd !important;
}

.text-data  .pen-green {
  background-color: initial;
  color: #128a00 !important;
}


.text-data  .pen-red {
  background-color: initial;
  color: #e71313 !important;
}

.grid-option:hover {
  background: #C1D0F0 !important;
    color: #254a9a !important;
}

.grid-option .grid-option-icon {
  color: #989898 !important;
  font-size: 18px !important;
}

.grid-option:hover .grid-option-icon {
  color:  #254a9a !important;
}

.grid-option .grid-option-label {
  font-size: 12px !important;
}

.data-updated{
  border-left: 4px solid rgb(211, 0, 56) !important;
  background-color: rgba(255, 42, 81, 0.19) !important;
}

