.header {
  background: rgb(36,74,154);
  background: $ta-background !important;
  height: $header-height;
 
  @include depth(1);
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: baseline !important;
  padding: 0;
  position:  relative !important;
}
.sub-header {
  background: rgb(36,74,154);
  background: #244a9a !important;
  height: 25px;
  @include depth(1);
  display: flex;
  align-content: center;
  justify-content: center !important;
  padding: 0;
  position:  relative !important;
}

.header-btn {
  color: #ffffff;
}

.user {
  margin-right: $main-margin;

  @include respond-below(xl) {
    margin-right: $main-margin-lg;
  }

  @include respond-below(lg) {
    margin-right: $main-margin-md;
  }

  @include respond-below(sm) {
    margin-right: $main-margin-xs;
  }
}

.header-logo {
  width: 200px;
  height: 60px;
  position: relative;
  left: 0;
  right: 0;
  margin-left:5px;

  .logo-building {
    width: 33%;
    height: 40px;
    background: url($ta-logo-path-building) no-repeat;
    background-size: contain;
  }

  .logo {
    width: 22%;
    height: 48px;
    background: url($ta-logo-path) no-repeat;
    margin-top: 10px;
    background-size: contain;
  }

  .logo-guru {
    width: 33%;
    height: 20px;
    background: url($ta-logo-full-path) no-repeat;
    background-position: center left;
    background-size: contain;
    margin-top: 13px;
  }

  .logo-mobile {
    width: 100%;
    height: 100%;
    background: url($logo-path-mobile) no-repeat;
    background-position: center center;
    background-size: contain;
  }

  @include respond-below(sm) {
    width: 80px;
  }

  @include respond-below(xxs) {
    width: 20px;
  }
}

.header-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0;
  flex-wrap: nowrap;
}

.breadcrumb{
  margin: 0 0;
}

.divider {
  width: 75%;
  background-color:  #c78893;
  height: 1px;
}


.breadcrumb_item {
  height: 100%;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-transform: capitalize;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0 2px;
  cursor: pointer;
  text-decoration: none;

  a{
    text-decoration: none;
    display: flex;
    align-items: center;
      .MuiSvgIcon-root{
        width:15px !important;
        height:15px !important;
        color:#ffffff !important;
        margin-right:3px;
      }
  }

  .MuiSvgIcon-root{
    width:15px !important;
    height:15px !important;
    color:#ffffff !important;
    margin-right:3px;
  }

  }
.breadcrumb-item::before {
    color:#ffffff !important
  }

  .g-footer{
    color: rgb(60, 75, 100);
    background: rgb(235, 237, 239);
    border-top: 1px solid #d8dbe0;
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    height: 30px;
    position: fixed;
    width:-webkit-fill-available;
  }


  .feedback {
  color: inherit;
  text-decoration: none;
  padding: 2px;
  text-transform: capitalize !important;
  margin-right: 10px !important;
  margin-top: 4px !important;
  transition: all ease 0.2s;
  height:29px !important;
}

.feedback {
  background:
    linear-gradient(
      to right,
      rgb(235, 237, 239),
      rgba(235, 237, 239)
    ),
    linear-gradient(
      to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(0, 100, 200, 1)
  );
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.feedback:hover {
  background-size: 0 3px, 100% 3px;
  color:#244a9a !important;
  transform: translateX(-5px);
}