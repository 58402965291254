.m-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 36rem;
}

.m-box {
  border-radius: 4px;
}

.box-textbox {
  height: 100%;
  width: 100%;
  resize: none;
  background-color: transparent;
}

.box-textbox::-webkit-input-placeholder,
.box-textbox:-ms-input-placeholder,
.box-textbox::placeholder {
  color: rgba(107, 114, 128, 1);
}

.box-textbox:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: #c1d0f0;
  box-shadow:  0 0 0 0.2rem #C7D2FE;
}

.box-body {
  display: flex;
  border-radius: 0.5rem;
 padding: 1px;
  color: rgba(255, 255, 255, 1);
}

.box-body > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(1rem * 0);
  margin-left: calc(1rem * calc(1 - 0));
}

.box-compose {
  position: relative;
  margin-top: 1px;
  flex-grow: 1;
  height:300px;
}

.autocomplete-panel {
  background: #c1d0f0;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  padding: 4px;
  height: 100%;
  left: 0px;
  width: 100%;
  max-width: 20rem;
  z-index:3000;
}

.autocomplete-source {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.autocomplete-source-stalled {
  filter: grayscale(1);
  opacity: 0.8;
}

.autocomplete-items {
  height: 100%;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 6px;
}

.autocomplete-item {
  display: flex;
  cursor: pointer;
  border-radius: 6px;
}

.autocomplete-item > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(0.25rem * calc(1 - 0));
  margin-bottom: calc(0.25rem * 0);
}

.autocomplete-item {
  padding: 2px;
}

.autocomplete-item-selected {
  background-color: #ffffff !important;
}

.account-highlighted {
  border-radius: 0.125rem;
  background-color: rgba(55, 65, 81, 1);
  color: currentColor;
}


