

.ck.ck-editor__top{
  position: sticky !important;
  top:0px;
}

:root {
  --ck-z-default: 400 !important
}

.ck.ck-toolbar  {
  border: 1px solid #c1d0f0 !important;
  padding: 0px !important;
  background-color: #c1d0f0 !important;
}

.ck.ck-toolbar > .ck-toolbar__items {
  margin-right: 0px !important;
  background-color: #c1d0f0 !important;
  .ck.ck-dropdown > .ck.ck-dropdown__panel-visible > .ck.ck-list-properties > .ck.ck-list-styles-list{
    display: flex;
    flex-wrap: wrap;
      .ck-button {
        color: #254a9a !important;
        .ck-icon {
          width: 40px;
          height: 40px;
        }
    }
  }
  .ck-button {
    color: #254a9a !important;
    .ck-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.ck-editor__main {
  min-height: inherit !important;
  height: 85% !important;
  .ck-content {
    min-height: inherit !important;
    height: 98% !important;
    overflow:auto;
  }
}

.g-text-editor {
  .ck.ck-editor {
    min-height: inherit !important;
    height: inherit !important;
    width: 100% !important;
  }
}

.g-text-editor .mention{
  background: #C1D0F0 !important;
  color: #254a9a !important;
  opacity: 1.0;
  padding: 5px !important;
}
