.__markerjs2_{
    z-index: 3001 !important;
    top:50px !important;
    height: -webkit-fill-available !important;
}

.__markerjs2_  img {
    margin-top:10px !important;
    height: -webkit-fill-available !important;
}

.marker-panel-row-custom{
    background-color: rgb(17, 17, 17);
}

.marker-panel-row-custom > div {
    margin:5px !important;
}