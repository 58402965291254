/* Menu*/

.sidebar-expanded {
  transition: width 0.9s ease-in-out !important;
  min-width: $main-menu-width!important;
  width: $main-menu-width !important;
}
.sidebar {
  min-width: 40px !important;
  width:40px;
  height: calc(100vh - #{$header-height}) !important;
  background: $foreground-color;
  z-index: 4;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.9s ease;
  cursor: pointer;

  .main-menu-nav {
    ul li {
      position: relative;
      padding: 2px;

      p {
        text-align: center;
        padding: 2px 10px;
        line-height: 20px;
      }

      a {
        height: $main-menu-item-height;
        padding: 0 2px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        //border-bottom: 1px solid $separator-color-light;
        color: $primary-color-a;
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;
        text-decoration: none !important;
        font-size: 13px;
        border-right: 1px solid $separator-color-light;
        border-bottom: 1px solid $separator-color-light;
        text-decoration: none !important;
        .MuiSvgIcon-root {
          width: 30px;
          margin: 4px;
        }
        .MuiTypography-body2 {
          color: $theme-color-2;
          padding: 4px;
        }
        &:hover,
        &:focus {
          color: transparent;
          background-color: $theme-color-2;

          .MuiSvgIcon-root {
            color: #ffffff !important;
          }

          .MuiTypography-body2 {
            color: #ffffff;
          }
        }
      }

      i {
        font-size: 32px;
        line-height: 42px;
      }

      &.active a {
        font-weight: "bold";
        border-radius: 4px;
        margin-right: 2px;
        background-color: #c1d0f0;
        .MuiSvgIcon-root {
          color: #244a9a !important;
          width: 25px !important;
        }

        .MuiTypography-body2 {
          color: #244a9a;
          margin-left: 5px;
          padding: 4px;
        }
      }

      &.active:after {
        content: " ";
        background: $theme-color-6;
        border-radius: 5px;
        position: absolute;
        width: 4px;
        margin-left: 2px;
        height: 80%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      &:last-of-type {
        a {
          border-bottom: initial;
        }
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width;
      height: calc(100% - #{$header-height-lg});
    }

    @include respond-below(lg) {
      width: $main-menu-width;
      height: calc(100% - #{$header-height-md});
    }

    @include respond-below(sm) {
      width: $main-menu-width;
      height: calc(100% - #{$header-height-xs});

      ul li i {
        font-size: 28px;
        line-height: 38px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }
}

.g-nav-tag {
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $sub-menu-width !important;
  padding: 4px;
  border-top: 1px solid #f3f3f3;
}

.sub-menu {
  min-width: $sub-menu-width !important;
  width:$sub-menu-width;
  display: flex;
  flex-direction: column;
  background: $foreground-color;
  z-index: 4;
  border-left: 1px solid $separator-color-light;
  @include depth(2);
  // transition: transform $menu-collapse-time;
  height: calc(100vh - #{$header-height});
  padding-right: 0;
  transition: width 0.5s;
  .sub-menu-nav {
    background: #ffffff;
     ul li {
      position: relative;
      padding: 2px;
      a {
        height: $sub-menu-item-height;
        padding: 0 2px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        //border-bottom: 1px solid $separator-color-light;
        color: $primary-color-a;
        transition: color $menu-collapse-time;
        transition: background $menu-collapse-time;
        text-decoration: none !important;
        transform: scale(1);
        .MuiSvgIcon-root {
          width: 40px !important;
         
        }
        .MuiTypography-body2 {
          padding: 4px;
        }
        &:hover,
        &:focus {
          color: $theme-color-6;
          background-color: $theme-color-6;
          box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          .MuiSvgIcon-root {
            color: #ffffff !important;
          }

          .MuiTypography-body2 {
            color: #ffffff;
            padding: 4px;
          }
        }
        &.active {
          background-color: $theme-color-6;
          border-radius: 4px;
          margin-right: 2px;
          .MuiSvgIcon-root {
            color: #ffffff !important;
            width: 35px !important;
            margin-left: 4px !important;
          }
  
          .MuiTypography-body2 {
            color: #ffffff;
            margin-left: 5px;
            padding: 4px;
          }
        }
        &.active:after {
          content: " ";
          background: #c1d0f0;
          border-radius: 5px;
          position: absolute;
          width: 4px;
          margin-left: 2px;
          height: 80%;
          top:  50%;
          transform: translateY(-50%);
          left: 0;
        }
      }

      i {
        font-size: 32px;
        line-height: 42px;
      }
      

      &:last-of-type {
        a {
          border-bottom: initial;
        }
      }
     }
  }
}

.sub-menu-nav {
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-title {
  color: #244a9af0 !important;
  padding: 0.75rem 1rem !important;
  margin-top: 1rem !important;
  font-size: 70% !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

// .menu-hidden #g-wrapper .main-menu {
//   transform: translateX(-$main-menu-width);
//   transition: transform $menu-collapse-time ease-out;
// }

.menu-show #g-wrapper .main-menu {
  display: block !important;
}

.nav-title-no-name{
  margin-top: 1rem !important;
  border-top: 1px solid #f3f3f3;
}