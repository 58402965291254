@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import "./mixins.scss";
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "./container/header.scss";
@import "./container/body.scss";
@import "./container/sidebar.scss";
@import "./container/editor.scss";
@import "./container/button.scss";
@import "./container/dropzone.scss";
@import "./container/custom.scss";
@import "./container/imageEditor.scss";
@import  "./container/review.scss";

