
html, body, #root, .App, #app-container{
  height: 100%;
}

#root{
  max-height: 100vh;
  overflow: hidden;
}

body,html {
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
  font-size: .95em;
}

body{
  background-color: #ebedef !important;
}

a{
  font-size: .95em;
}
img{
  max-width: 100%;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.preloader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #209e91;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.preloader > div:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e85656;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.preloader > div:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dfb81c;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.preloader > div:after,
.preloader > div:before {
  content: "";
  position: absolute;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.comments-show-all  {
  background-color: #d08f8e !important;
  color:#ffffff !important;
}
.report-fullScreen-comments   {
  background-color: #d08f8e !important;
  color:#ffffff !important;
}

.photo-frame {
  display: block;
  box-sizing: content-box;
  position: relative;
  background: #ffffff;
  cursor: grab;
  border-radius: 4px;
  box-shadow:
    rgb(0 0 0 / 20%) 0 3px 3px -2px,
    rgb(0 0 0 / 14%) 0 3px 4px 0,
    rgb(0 0 0 / 12%) 0 1px 8px 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.photo-frame,
.photo-frame img {
  outline: none;
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.photo-frame.overlay {
  cursor: grabbing;
}

.photo-frame.active,
.photo-frame.active img {
  opacity: 0.3;
}

.photo-frame.insertBefore:before,
.photo-frame.insertAfter:after {
  content: "";
  position: absolute;
  background-color: #2196f3;
  border-radius: 4px;
}

.photo-frame.insertBefore:before,
.photo-frame.insertAfter:after {
  top: 0;
  bottom: 0;
  width: 4px;
}

.photo-frame.insertBefore:before {
  left: -17px;
}

.photo-frame.insertAfter:after {
  right: -17px;
}

.drag-item.overlay {
  cursor: grabbing;
  background-color: #ffffff;
  opacity: 0.7;
}
.drag-item.active{
  opacity: 0.3;
}

.drag-item.insertBefore, .drag-item.insertAfter {
  border: 4px solid #d08f8e;
}


