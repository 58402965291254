/* Dropzone */
.dropzone {
    width:95%;
    padding: 10px;
    margin: 10px;
    border: 4px dashed  #27496d !important;
    outline: none;
    cursor: pointer;
    height: 95%;
  }
  .dropzone-active {
    background-color: #f1e2ff;
    border: 5px solid #2fd7b5;
  }
  .dropzone-content {
    align-self: center;
    font-size: 24px;
  }
  
  /* Images */
  .file-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  
  .img-container {
    position: relative;
    width: 300px;
    max-width: 300px;
    height: 90%;
    max-height: 250px;
  }
  
  .image {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .img-overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #fff; 
    width: 100%;
    height: 100%;
    transition: .5s ease;
    opacity:1;
    color: white;
    font-size: 20px;
    text-align: left;
    padding: 0px 5px;
  }
  
  .img-container:hover .img-overlay {
    opacity: 1;
  }
  
  .img-icon {
    color:  #fff !important;
    font-size: 100px;
  }
  
  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .file-item {
      width: calc(50% - 22px);
      height: 200px;
    }
  }
  
  /* Mobiles */
  @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }
    .file-item {
      width: calc(100% - 22px);
      height: 200px;
    }
  }