// Here you can add other styles

html body {
  font-size: 0.875rem !important;
  overflow-y: auto;
  overflow-anchor: none;
}
.c-main {
  padding-top: 1em !important;
}

.c-leftMargin {
  margin-left: 256px !important;
}

.projectAppBar {
  background-color: #254a9a;
}

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
}

.auth-bg {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
}

.welcomeDivider {
  background-color: #ffffff !important;
  height: 4px !important;
  width: 20% !important;
  margin-left: 20px !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
}
.loginDivider {
  background-color: #254a9a !important;
  height: 4px !important;
  width: 10% !important;
  margin-left: 43% !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
}
/* mouse over link */
.projectMenuLink > a:hover {
  color: red !important;
}

.dasboardContainer {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 32%;
  left: 60%;
  transform: translate(-50%, -50%) rotate(6deg);
}

.MuiTypography-body1 {
  font-size: 0.875rem !important;
  margin-top: 3px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}
.MuiListItemIcon-root {
  min-width: 40px !important;
}

.dropdown-menu {
  font-size: 0.875rem !important;
}
.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.edit-padding {
  padding-top: 20px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.add-user {
  color: #ffffff !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-30 {
  margin-top: 30px;
}
.margin-top-7 {
  margin-top: 7px;
}

.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}

.margin-left-25 {
  margin-left: 25%;
}

.roles-select__menu {
  font-size: 85% !important;
}

.roles-select__multi-value {
  background-color: rgba(0, 135, 90, 0.1) !important;
  display: flex !important;
  min-width: 0px !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  margin: 2px !important;
  color: rgb(0, 135, 90) !important;
  font-size: 85% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  overflow: hidden !important;
  padding: 3px 3px 3px 6px !important;
}

.edit-button {
  border: none;
  color: #0866c7fc !important;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
  cursor: pointer;
}

td :focus {
  outline: none !important;
}

button :focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
  width: 550px;
}
.margin-alert {
  margin-left: 15rem !important;
  margin-right: 0em !important;
  margin-top: 3em !important;
}

.width-25 {
  width: 25rem !important;
}

.ta-logo {
  background-color: #254a9a !important;
  justify-content: left !important;
}
.ta-guru-logo {
  width: 100%;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 10.5px;
  margin-left: 5px;
}
.sidebar-bg-color {
  background-color: #254a9a;
}

.forgot-password-icon {
  color: #ffff !important;
  background: #254a9a;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
  margin-top: -50px;
}

.center-align {
  text-align: center;
}
.text-end-align {
  text-align: end !important;
}

.size-font {
  font-size: 90% !important;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.preloader > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #209e91;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
.preloader > div:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e85656;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.preloader > div:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #dfb81c;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.preloader > div:after,
.preloader > div:before {
  content: "";
  position: absolute;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.disableBg {
  -webkit-filter: blur(13px);
  -moz-filter: blur(13px);
  -o-filter: blur(13px);
  -ms-filter: blur(13px);
  filter: blur(13px);
}

.login-card-footer {
  color: transparent !important;
  background: transparent !important;
  border-top: 0px !important;
}
.full-width {
  width: 100% !important;
}

.user-profile-main-div {
  display: flex;
  outline: 0;
  position: relative;
  justify-content: center;
}

.edit-user-main-div {
  outline: 0;
  position: relative;
  justify-content: left;
}

.edit-user-grid-container {
  justify-content: left !important;
}
.custom-appbar {
  background-color: #254a9a !important;
  position: sticky !important;
}
.custom-toolbar {
  background-color: #254a9a !important;
  margin: 5px;
}

.custom-toolbar-section {
  background-color: #254a9a;
    color: #ffffff;
    min-height: 30px !important;
    margin-left: 5px;
    border-radius: 5px;
    width: 98%;
    margin-bottom: 5px;
}

.custom-toolbar-sub-section {
    color: #254a9a;
    min-height: 30px !important;
    margin-left: 5px;
    border-radius: 5px;
    width: 95%;
    margin-bottom: 5px;
    font-weight: bold;
    padding: 0px;
}

.custom-section-heading {
  border-bottom: 2px solid #254a9a;
  color: #254a9a;
  font-weight: 400;
  margin-bottom: 14px;
  line-height: 39px;
  width: 95%;
  margin-left: 20px !important;
}

.padding12 {
  padding: 12px;
}
.margin-top-12 {
  margin-top: 12px;
}

.margin-padding-05 {
  padding: 5px !important;
  margin-bottom: 5px !important;
}

.bottom-margin-10 {
  margin-bottom: 10px;
}

.create-projectButton {
  margin-right: 0px !important;
}

.bg-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.bg-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.bg-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.bg-pink {
  color: #fff;
  background-color: #d08f8e !important;
  border-color: #d08f8e !important;
}
.bg-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.bg-extern {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.chip-class {
  height: 20px !important;
  margin-right: 2px !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
}

.expandable {
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  max-height: 100%;
  height: 100%;
}

.quill {
  height: 100%;
}
.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
}

.MuiFormLabel-root {
  font-size: 0.875rem !important;
}

.userpic-wrapper:hover .user-profile-image {
  opacity: 0.3;
}

.userpic-wrapper:hover .image-bottom {
  opacity: 1;
}

.change-image {
  background-color: #254a9a !important;
  color: white !important;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 10px !important;
}
.change-image-input {
  display: none;
}
.image-bottom {
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  margin-top: -15px !important;
}

.img-thumbnail {
  width: 200px;
  height: 200px;
}
.img-avatar {
  vertical-align: middle;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.align-password-link {
  text-align: right;
  padding-right: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.dropzone-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-height: 120px;
  overflow: auto;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  background-color: #254a9a !important;
}

.dzu-dropzone {
  overflow: auto !important;
  min-height: 250px !important;
}

.dropzone-cloud-icon {
  font-size: 6rem !important;
}

.dropzone-input-content {
  display: grid;
  text-align: center;
  color: #ffffff;
}

.dropzone-choose-file {
  padding: 10px !important;
  cursor: pointer !important;
  color: #254a9a !important;
  background-color: #fff !important;
}

.table-header {
  background-color: #254a9a !important;
  color: #fff !important;
}

.textcentered {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}

.edit-textarea {
  height: 30vh !important;
  width: 30vw !important;
}

.scroll-hv {
  height: 85vh;
  background-color: #ffff !important;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.addUserDialog {
  max-height: 80%;
}

.addUserCardHeight {
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
}

.dialogTitle {
  color: #254a9a !important;
}

.title {
  color: #254a9a !important;
}

.projectActionButtons {
  display: block !important;
  text-align: end;
}
.projectUserButtons {
  margin: 5px;
}

.boxShadow {
  box-shadow: none !important;
}

.boxShadow > .MuiPaper-elevation2 {
  box-shadow: none !important;
}
.divider {
  width: 100% !important;
  height: 2px !important;
}
.assetCard {
  min-height: 100%;
  max-width: 340px;
  transition: "0.2s";
  margin: 1% 1%;
}

.assetCard:hover {
  transform: scale(1.1);
}

.emptyAssets {
  margin: 25% 10%;
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 0 10px;
  padding: 15px 5px;
}
.assetName {
  margin: 4% 4%;
}

.margin {
  margin: 1% 1%;
}

.assetsActionIcons {
  text-align: center;
  margin: 2% 0%;
}

.asset-user-listaction {
  width: 30%;
}

.card-contents {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0px !important;
}
.card-hdr {
  text-align: left !important;
  padding-left: 16px !important;
  padding-top: 16px !important;
  text-transform: capitalize;
}

.scrollspy {
  background-color: white;
  margin-top: 0;
  top: 0;
  right: 0;
}

.selectedActive {
  color: #f05454 !important;
}
.subAssetScrollSpy {
  height: 100%;
  overflow-y: scroll !important;
  background-color: transparent !important;
}

.left {
  float: left;
}

.right {
  float: right;
}
.subAssetGridContainer {
  margin-bottom: 5px !important;
}

.border-bottom-subAsset {
  border-bottom: 2px solid #254a9a !important;
  margin-left: 1.5%;
  margin-right: 1%;
}
.width-subAsset {
  width: 92%;
}

.categories-toolBar {
  color: #fff !important;
  background-color: #27496d !important;
}

.subcategories-toolBar {
  margin-left: 0%;
  height: 100%;
  color: #ffffff !important;
  background-color: #27496d !important;
}

.second-subcategories-toolBar {
  margin-left: 0%;
  height: 45px !important;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  color: #ffffff !important;
  background-color: #9ba4b4 !important;
  position: sticky !important;
  top: 1%;
}

.width-subcategories {
  width: 92%;
}

.no-defectcount {
  color: #b0b0b0 !important;
}

.defectcount {
  color: #000000 !important;
}

.element-toolbar-nocostdefect {
  margin-left: 15px !important;
  border-left: 6px solid #b0b0b0 !important;
}

.element-toolbar {
  margin-left: 15px !important;
  border-left: 6px solid #254a9a;
}

.element-empty-toolbar {
  border-left: 6px solid #d9534f !important;
  width: 100% !important;
}

.subAsset-toolBar {
  color: #fff;
  background-color: #142850;
}

.node-heading {
  color: #254a9a;
  font-weight: 400 !important;
  line-height: 40px !important;
  width: 100%;
}

.actions-div {
  margin-bottom: 5px !important;
  text-align: right;
}
.action-Buttons {
  padding-left: 10px;
}

.icon-color {
  color: #ffffff;
}

.custom-left-tabs {
  flex-direction: inherit !important;
  justify-content: left !important;
  text-align: left !important;
}

.defectCard {
  min-height: 240px;
  max-width: 240px;
  transition: "0.2s";
  margin: 2% 10%;
}
.emptyDefect {
  margin: 5% 1%;
  border-radius: 15px;
  box-shadow: 0 0 10px;
  padding: 15px 5px;
}

.defectCard:hover {
  transform: scale(1.1);
}

.createDefect {
  width: 75%;
  float: left;
  padding-top: 15px !important;
}

.createDefectButton {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.empty-accordion {
  margin-bottom: 2% !important;
  margin-top: 1% !important;
}

.element-list {
  margin-bottom: 10px !important;
  background-color: #ffffff !important;
}
.delete-element {
  padding: "4px";
  z-index: 1;
}

.measureHeading {
  border-bottom: 2px solid #254a9a;
  color: #254a9a;
  font-weight: 400;
  margin-bottom: 14px;
  line-height: 39px;
  width: 95%;
  margin-left: 20px !important;
  padding-left: 0px !important;
}

.subAssetMenuFab {
  background-color: #f7a63e !important;
  color: #ffffff !important;
  position: absolute !important;
  right: 16px !important;
  bottom: 50px !important;
  z-index: 1300 !important;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0px;
}

.MuiDrawer-paper::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.MuiDialog-container::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}
.MuiDialog-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0px;
}
.MuiDialog-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0px;
}

.subassetTree {
  margin-top: 0px !important;
  margin-right: 0px !important;
  height: calc(100vh - 180px);
}

.priceGrid {
  border: 1px solid black;
}

.prioritySlider {
  width: 70% !important;
  float: right;
  margin-right: 10%;
}

.measure-section {
  background-color: #6d8ba6;
}

.selected-measure {
  color: #c2e76b !important;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px 3px #f8d000;
  }
  40% {
    box-shadow: 0 0 20px 3px #f8d000;
  }
  60% {
    box-shadow: 0 0 20px 3px #f8d000;
  }
  100% {
    box-shadow: 0 0 -10px 3px #f8d000;
  }
}

.mark-completed-glow {
  animation: glowing 1000ms infinite;
}

.subAssetMenudrawer {
  padding-top: 0px !important;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

.buzz-lifespan-warning {
  -webkit-animation: ring 4s 0.7s ease-in-out 2;
  transform-origin: 50% 4px;
  margin-top: 6px;
  margin-right: 5px;
}

.buzz-warning-text {
  margin-right: 30px;
}

.capex-grid {
  margin-top: -20px !important;
}

.category-drag-List {
  height: calc(100vh - 245px);
  overflow-y: scroll;
  background-color: transparent !important;
}

.category-list {
  margin: 4% 0%;
  text-transform: uppercase;
}

.categoryIcon {
  margin-right: 3px;
}

.text-center {
  margin: 40% 15%;
}

.slide-out-right {
  -webkit-animation: slide-out-right 1.5s ease-in-out infinite alternate both;
  animation: slide-out-right 1.5s ease-in-out infinite alternate both;
  width: 100%;
  height: 5vh;
  background-color: #05e8ba;
  background-image: linear-gradient(315deg, #05e8ba 0%, #087ee1 74%);
}

.slide-out {
  -webkit-animation: slide-out 1s ease-in-out infinite alternate both;
  animation: slide-out 1s ease-in-out infinite alternate both;
  width: 100vh;
  height: 1vh;
  background: linear-gradient(to right, #985796 0%, #1c2082 100%);
  border-radius: 15px 50px 30px;
}

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(-100vh);
    transform: translateX(-100vh);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100vh);
    transform: translateX(100vh);
    opacity: 1;
  }
}
.marginTop-25 {
  margin-top: -25px !important;
}

.track {
  display: inline-block;
  height: 10px;
  width: 90%;
  margin: 0;
}

.tick:before {
  content: "";
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 5px;
  width: 2px;
  transform: translate(-50%, 0.7rem);
}

.tickLabel {
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
}

.shortTerm {
  content: "Short Term";
  background-color: #e85656;
  height: 100%;
}

.mediumTerm {
  background-color: #dfb81c;
  height: 100%;
}

.longTerm {
  background-color: #209e91;
  height: 100%;
}

.handleButton {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
}

.handleLable {
  background: #254a9a;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: white;
  font-weight: normal;
  transform: translateY(0) scale(0.9);
}

.grid-container {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 5px;
}

.grid-item {
  padding: 2px;
  font-size: 15px;
  text-align: center;
  margin-right: 5px;
  color: #ffffff;
}

.img-thumbnail {
  background-color: transparent !important;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cardImage {
  height: 200px !important;
}

.ql-toolbar {
  background-color: #a9a9a9;
  margin-bottom: 10px;
  border-radius: 5px;
}

.ql-container {
  border: 1px solid #ccc;
}

.content-container {
  position: relative;
  width: 350px;
  padding: 15px;
  z-index: 5;
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.card-header {
  background-color: #ffffff !important;
  border-bottom: 0px !important;
}

.user-icon {
  stroke: #04a9f5;
  stroke-width: 3;
  size: 35;
}

.auth-font {
  font-family: monospace;
}

.left-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.login-button {
  background-color: #194a9a;
  color: #ffffff;
  padding-right: 10px !important;
}
.login-checkbox {
  cursor: pointer;
}

.remember-me-label {
  margin-left: 10px;
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  color: #545b62;
}

.login-header {
  font-family: "Titillium Web", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: none;
}

@media only screen and (max-width: 768px) {
  .auth-wrapper {
    max-width: 360px;
  }
}

@media only screen and (max-width: 575px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}

.grouping {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
  > .rdg {
    flex: 1;
  }
}

.options {
  display: flex;
  gap: 8px;
  text-transform: capitalize;
}

.filter {
  width: 100%;
  padding: 4px;
  font-size: 14px;
}
.filter-cell {
  line-height: 70px !important;
  margin-left: 0px !important;
}

.rdg-root {
  display: flex;
  margin-left: 5px;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  > .rdg {
    flex: 1;
  }
}
.header-cell{
  font-weight: bold;
  color: #fff;
  //color: #ffffff;
  //border-radius: 4px !important;
  padding: 0px !important;
}
.section-cell{
  background-color: #398b93 !important;
  color: #fff;
  font-weight: bold;
  border: 0px;
}

.rdg-cell-frozen{
  padding: 0px !important;
}

.group-cell{
  background-color: #e2efda !important;
  color: #244a9a;
  font-weight: bold;
  border: 0px;
}
.highlightClassname {
  .rdg-cell {
    background-color: #149690;
    color: white;
  }
  &:hover .rdg-cell {
    background-color: #fca5a5;
  }
}

.rowUpdate {
  background-color: #5ba064;
}

.rdg-summary-row {
  background-color: #254a9a !important;
  color: white;
}

.textEditor {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 6px 0 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--color);
  background-color: var(--background-color);
  font-family: inherit;
  font-size: var(--font-size);
  &:focus {
    border-color: var(--selection-color);
    outline: none;
  }
  &::placeholder {
    color: #999;
    opacity: 1;
  }
}

.chapterCard{
  height: calc(100vh - 200px);
}
.chapterList{
    height: 100%;
    overflow-y: auto;
}

.ql-editor{
  min-height:250px;
}

.customQuill .ql-container {
  height: 75% !important;
}

.customQuill .ql-container .ql-editor {
  min-height:150px;
}

.ql-mention-list-container {
  width: fit-content;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.notSaved {
  border-left: 4px solid red !important;
}
.saved{
  border-left: none !important;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

.ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  max-width: 100% !important;
}


  
.typing-demo {
  width: 350px;
  animation: typing 2s steps(33);
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.bird {
	background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
	background-size: auto 100%;
	width: 88px;
	height: 125px;
	will-change: background-position;
	
	animation-name: fly-cycle;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;

	&--one {
		animation-duration: 1s;
		animation-delay: -0.5s;		
	}
	
	&--two {
		animation-duration: 0.9s;
		animation-delay: -0.75s;
	}
	
	&--three {
		animation-duration: 1.25s;
		animation-delay: -0.25s;
	}
	
	&--four {
		animation-duration: 1.1s;
		animation-delay: -0.5s;
	}

}

.bird-container {
	position: absolute;
	transform: scale(0) translateX(-10vw);
	will-change: transform;
	
	animation-name: fly-right-one;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	
	&--one {
		animation-duration: 15s;
		animation-delay: 0;
	}
	
	&--two {
		animation-duration: 16s;
		animation-delay: 1s;
	}
	
	&--three {
		animation-duration: 14.6s;
		animation-delay: 9.5s;
	}
	
	&--four {
		animation-duration: 16s;
		animation-delay: 10.25s;
	}
	
}

@keyframes fly-cycle {
	
	100% {
		background-position: -900px 0;
	}
	
}

@keyframes fly-right-one {
	
	0% {
		transform: scale(0.3) translateX(-10vw);
	}
	
	10% {
		transform: translateY(2vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(0vh) translateX(30vw) scale(0.5);
	}
	
	30% {
		transform: translateY(4vh) translateX(50vw) scale(0.6);
	}
	
	40% {
		transform: translateY(2vh) translateX(70vw) scale(0.6);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.6);
	}
	
	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
}

@keyframes fly-right-two {
	
	0% {
		transform: translateY(-2vh) translateX(-10vw) scale(0.5);
	}
	
	10% {
		transform: translateY(0vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(-4vh) translateX(30vw) scale(0.6);
	}
	
	30% {
		transform: translateY(1vh) translateX(50vw) scale(0.45);
	}
	
	40% {
		transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.45);
	}
	
	51% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
}

#trade-dialog{
  max-width: 600px;
}
.report .ql-toolbar{
  position: sticky;
  top: 0px;
  z-index: 2;
}

.ql-clipboard {
  position: fixed;
}

.rdg.fill-grid{
  block-size: 100%;
}

.textEditor {
  appearance: none;
  width:100%;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  background-color: #ffffff;
  border: 2px solid #ccc;
  vertical-align: top;
  font-family: inherit;
}

.rdg-cell-drag-handle{
  block-size: 15px !important;
  inline-size: 15px !important;
}

.rdg-cell[aria-selected=true]{
  outline: 2px solid #398b93 !important;
  outline-offset: -2px !important;
}

.rdg-cell-dragged-over{
  outline: 1px dashed #66afe9 !important;
  outline-offset: -2px !important;
}

.even-cell-background{
  background-color:#f3fff0 !important;
}

.header-cell{
  background-color: #254a9a !important;
}

.data-grid-container{
  border-radius: 4px;
  margin-left:2px
}

.rdg-row {
  border-bottom: 1px solid #dddddd !important;
  margin-bottom: 1px;
}

.rdg-row-even {
  background-color: lighten(#398b93, 55%) !important;
}

.rdg-summary-row .rdg-cell{
  background-color: #244a9a !important;

}
.rdg-checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  inset: 0;
  justify-content: center;
  margin-inline-end: 1px;
  position: absolute;
}
.rdg-cell {
  padding-inline: 0px !important;
}