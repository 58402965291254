.box {
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease;
    padding: 10px;
    border-radius: 4px;
  }
  
  .elevated {
    box-shadow: 0 10px 20px rgba(0, 0, 255, 0.2); /* Blue shadow for elevation */
    transform: translateY(-10px); /* Makes the box appear raised */
    z-index: 1800;
  }

  .custom-item-comments {
    display: flex;
    flex-direction: column;
    margin:4px;
    border-bottom: 1px solid #244a9a !important; 
  }

.custom-item-comments.ck-button.ck-on{
    background: #C1D0F0 !important;
    color:"#244a9a" !important;
}

.render-comments  .mention{
  background: #C1D0F0 !important;
    color: #254a9a !important;
}

.render-comments li{
  list-style-type: square !important;
}

.render-comments li::marker {
  color:#244a9a !important;
}
.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

@keyframes dots {
  0%, 20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80%, 100% {
    content: "...";
  }
}

.editing-animation::after {
  display: inline-block;
  width: 1em;
  animation: dots 3s steps(1, end) infinite;
  content: "";
}

.editing-indicator  .mention{
  background: #C1D0F0 !important;
  color: #254a9a !important;
  opacity: 1.0;
  padding: 5px !important;
}

.locked-indicator{
  background-color:#9900301a !important;
  opacity: 0.60;
}

.render-comments  .marker-yellow {
  background-color: #fdfd77 !important;
}

.render-comments  .marker-green {
  background-color: #62f962 !important;
}

.render-comments  .marker-pink {
  background-color: #fc7899 !important;
}


.render-comments  .marker-blue {
  background-color:#72ccfd !important;
}

.render-comments  .pen-green {
  background-color: initial;
  color: #128a00 !important;
}



.render-comments  .pen-red {
  background-color: initial;
  color: #e71313 !important;
}

.render-comments  .pen-green {
  background-color: initial;
  color: #128a00 !important;
}

.render-comments  .pen-green {
  background-color: initial;
  color: #128a00 !important;
}

.review-data .marker-yellow {
    background-color: #fdfd77 !important;
  }
  
  .review-data .marker-green {
    background-color: #62f962 !important;
  }
  
  .review-data .marker-pink {
    background-color: #fc7899 !important;
  }
  
  
  .review-data  .marker-blue {
    background-color:#72ccfd !important;
  }
  
  .review-data  .pen-green {
    background-color: initial;
    color: #128a00 !important;
  }
  
  
  
  .review-data .pen-red {
    background-color: initial;
    color: #e71313 !important;
  }
  
  .review-data .pen-green {
    background-color: initial;
    color: #128a00 !important;
  }
  
  .review-data  .pen-green {
    background-color: initial;
    color: #128a00 !important;
  }

  .text-primary{
    color:#244a9a !important;
  }
